import {
  Avatar,
  Box,
  MenuItem,
  Button,
  Link,
  ButtonGroup,
  Divider,
  FormLabel,
  Container,
  Grid,
  styled,
  Typography,
  FormControlLabel,
  Popover,
  Card,
  CardContent,
  Stack,
  Dialog,
  DialogContent,
  TextField,
  InputAdornment,
  Hidden,
  IconButton,
  Checkbox,
  Tooltip,
} from "@mui/material";
import {
  KeyboardArrowDown,
  Email,
  Person,
  Contacts,
  Lock,
  Facebook,
  Call,
  FiberManualRecord,
  Menu,
  Close,
  Verified,
} from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useReducer, useRef, useState } from "react";
import LOGO from "../assets/logo_2.png";
import EMAIL from "../assets/email_verify.png";
import Google from "../assets/google.svg";
import {
  loginReducer,
  signupReducer,
  loginState,
  signupState,
} from "../reducers";
import { LoadingButton } from "@mui/lab";
import { useDispatch, connect } from "react-redux";
import {
  userSignUp,
  userLogin,
  signoutUser,
  getUserData,
  googleOAuth,
  facebookOAuth,
  googleOneTap,
} from "../actions";
import SideBar from "./SideBar";
import base from "../api/apis";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";

const WrapBox = styled(Box)(({ theme }) => ({
  padding: "1rem 0",
  borderBottom: `1px solid ${grey[300]}`,
}));

const FlexBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  "& img": {
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
}));

const DownArrow = styled(KeyboardArrowDown)(({ theme }) => ({
  fontSize: "1rem",
}));

const NavItem = styled(Link)(({ theme }) => ({
  cursor: "pointer",
  margin: "7px 0",
  fontSize: ".95rem",
  lineHeight: 1.4,
  "&:hover": {
    color: theme.secondaryColor,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: ".5rem",
  marginBottom: "1.5rem",
}));

const NavLink = styled(Link)(({ theme }) => ({
  transition: "all .2s ease",
  fontFamily: "Urbanist",
  "& p": {
    fontWeight: 400,
  },
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: ".5rem",
    marginBottom: "1rem",
  },
  logo: {
    width: "60%",
    display: "block",
    margin: "0 auto",
    marginBottom: "1rem",
  },
  label: {
    fontSize: "1rem",
  },
  flex: {
    marginBottom: "2rem",
    display: "flex",
    gap: "1rem",
    justifyContent: "center",
    alignItems: "center",
  },
  btnGoogle: {
    padding: ".5rem 0",
    borderRadius: "8px",
    textTransform: "none",
    marginBottom: "1rem",
  },

  hoverActive: {
    borderBottom: `3px solid ${theme.secondaryColor}`,
  },
}));

const NavBar = (props) => {
  const {
    auth,
    currentUser,
    userSignUp,
    userLogin,
    signoutUser,
    googleOAuth,
    googleOneTap,
    facebookOAuth,
  } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const [anchorElProfile, setAnchorElProfile] = React.useState(null);
  const [isLogin, setIsLogin] = React.useState(true);
  const [loading, setLoading] = useState(false);
  const [openDraw, setOpenDraw] = useState(false);
  const [forgetPass, setForgetPass] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [page, setPage] = useState(1);
  const ref = useRef();
  const googleLink = useLocation().search.split("&")[0];
  const facebookLink = useLocation().search.split("&")[2];
  const path = useLocation().search;
  const location = useLocation().pathname;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  // Google Auth OneTap init
  useEffect(() => {
    const handleGoogleOneTap = () => {
      window?.google?.accounts?.id.initialize({
        client_id:
          "61807210049-7akpgsb23ro9r1a495121mt2f3t3pdsb.apps.googleusercontent.com",
        callback: (response) => googleOneTap(response?.credential),
      });
      window?.google?.accounts?.id.prompt();
    };

    if (currentUser) {
      return;
    } else {
      handleGoogleOneTap();
    }
    // eslint-disable-next-line
  }, [googleOneTap]);

  useEffect(() => {
    if (path) {
      if (googleLink?.startsWith("?code")) {
        //console.log('signing with Google')
        let googleAuthCode = path.split("&")[0].split("=")[1];
        googleAuthCode = _.replace(googleAuthCode, "%2F", "/");
        googleOAuth(googleAuthCode, location);
      }
    }
    if (path) {
      if (facebookLink?.startsWith("code")) {
        //console.log('signing with Facebook')
        const facebookAuthCode = path.split("&")[2].split("=")[1];
        facebookOAuth(facebookAuthCode, location);
      }
    }
  }, [path, location, googleLink, facebookLink, googleOAuth, facebookOAuth]);

  // Popover states
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);
  const open4 = Boolean(anchorEl4);
  const openProfile = Boolean(anchorElProfile);

  // Sidebar Handler
  const toggleDrawer = () => {
    setOpenDraw(!openDraw);
  };

  // Auth Modal Reducers & States
  const [signupDetails, signupDispatch] = useReducer(
    signupReducer,
    signupState
  );
  const [loginDetails, loginDispatch] = useReducer(loginReducer, loginState);

  // Popover Function Calls
  const handleClick = (event) => {
    setAnchorElProfile(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElProfile(null);
  };
  const handlePopoverOpenBuy = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverCloseBuy = () => {
    setAnchorEl(null);
  };
  const handlePopoverOpenSell = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handlePopoverCloseSell = () => {
    setAnchorEl2(null);
  };
  const handlePopoverOpenAgent = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const handlePopoverCloseAgent = () => {
    setAnchorEl3(null);
  };
  const handlePopoverOpenBrokerage = (event) => {
    setAnchorEl4(event.currentTarget);
  };
  const handlePopoverCloseBrokerage = () => {
    setAnchorEl4(null);
  };

  // Auth - Login / Signup
  const onSubmit = async (type) => {
    if (type === "login") {
      // console.log(loginDetails)

      //do validation checks
      if (loginDetails.email === "" || loginDetails.password === "") {
        return;
      }
      if (!loginDetails.email.includes("@")) {
        dispatch({ type: "ERROR", payload: "Invalid email address" });
        return;
      }
      if (loginDetails.password.length < 8) {
        dispatch({
          type: "ERROR",
          payload: "Password must be more than 8 characters ",
        });
        return;
      }
      const data = {
        email: loginDetails.email,
        password: loginDetails.password,
      };
      setLoading(true); //loader
      const status = await userLogin(data);
      if (status === 200) {
        setLoading(false);
        loginDispatch({ type: "RESET" }); //reset loginState
        dispatch({ type: "AUTH_CLOSE" }); // close auth modal
      } else {
        setLoading(false);
      }
    }
    if (type === "signup") {
      //do validation checks
      if (
        signupDetails.firstname === "" ||
        signupDetails.lastname === "" ||
        signupDetails.phone === "" ||
        signupDetails.email === "" ||
        signupDetails.password === "" ||
        signupDetails.confirmPassword === "" ||
        signupDetails.zip === ""
      ) {
        return;
      }
      //do password validation checks
      if (signupDetails.password !== signupDetails.confirmPassword) {
        dispatch({ type: "ERROR", payload: "Passwords do not match" });
        return;
      }
      const data = {
        category: "real_estate_agent",
        realty_agent: signupDetails.isAgent,
        broker: signupDetails.isBroker,
        phone: signupDetails.phone,
        zip_code: signupDetails.zip,
        user: {
          email: signupDetails.email,
          password: signupDetails.password,
          first_name: signupDetails.firstname,
          last_name: signupDetails.lastname,
        },
      };
      //console.log(data)
      setLoading(true); //loader
      const status = await userSignUp(data, signupDetails.isAgent);
      if (status === 200) {
        setLoading(false);
        signupDispatch({ type: "RESET" }); //reset signupState
        dispatch({ type: "AUTH_CLOSE" }); // close auth modal
      } else {
        setLoading(false);
      }
    }
  };

  // Auth Signout
  const signOut = () => {
    signoutUser();
  };

  // Oauth with Google & Facebook
  const googleAuth = () => {
    window.location.assign(
      `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${window.location.origin}/&prompt=consent&response_type=code&client_id=61807210049-7akpgsb23ro9r1a495121mt2f3t3pdsb.apps.googleusercontent.com&scope=openid%20email%20profile&access_type=offline`
    );
  };

  const facebookAuth = async () => {
    window.location.assign(
      `https://www.facebook.com/v15.0/dialog/oauth?client_id=478122010963405&redirect_uri=${window.location.origin}/&state=login&return_scopes=true&scope=email`
    );
  };

  // Password Reset
  const onPassReset = async () => {
    if (resetEmail === "" || !resetEmail.includes("@")) return;
    setLoading(true);
    // connect to endpoint
    await base
      .post("/core/reset-password/", {
        email: resetEmail,
        url: window.location.origin,
      })
      .then((res) => {
        //console.log(res)
        setLoading(false);
        setPage(2);
        dispatch({ type: "SUCCESS", payload: "Password reset link sent" });
      })
      .catch((err) => {
        //console.log(err)
        setLoading(false);
        dispatch({ type: "ERROR", payload: err.response.data.data });
      });
  };

  const accessDashboard = (link) => {
    if (currentUser && currentUser?.is_email_verified) {
      const accessToken = localStorage.getItem("uta");
      const refreshToken = localStorage.getItem("utr");
      window.location.assign(
        `${process.env.REACT_APP_BPOHOMESTECH_REDIRECT}?uta=${accessToken}&utr=${refreshToken}&link=${link}`
      );
    } else {
      handleClose();
      dispatch({ type: "AUTH_LOGIN" });
    }
  };

  const routeToPage = (e, link) => {
    e.stopPropagation();
    navigate(link);
  };

  // Auto focus Email token
  const autoFocusHandler = (event) => {
    const element = event.target;
    const nextSibling = element.nextElementSibling;
    nextSibling ? nextSibling.focus() : element.blur();
  };

  // Verify Email Refs
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();

  const resendCode = async () => {
    try {
      dispatch({
        type: "SUCCESS",
        payload: "Sending new code. Please wait...",
      });
      const { data: res } = await base.post("core/resend-otp/", {
        user_id: currentUser?.id,
      });
      if (res?.status === "success") {
        dispatch({
          type: "SUCCESS",
          payload: "New activation code has been sent to your email",
        });
      }
    } catch (error) {
      dispatch({ type: "ERROR", payload: "Sorry, could not send code" });
      return;
    }
  };

  const verifyAccount = async () => {
    const code =
      ref1.current?.value +
      ref2.current?.value +
      ref3.current?.value +
      ref4.current?.value +
      ref5.current?.value +
      ref6.current?.value;
    if (code === "") {
      dispatch({
        type: "ERROR",
        payload: "Invalid. Please provide activation code",
      });
      return;
    }
    if (code?.length < 6) {
      dispatch({
        type: "ERROR",
        payload: "Invalid activation code. Please check your code",
      });
      return;
    }
    try {
      setLoading(true);
      const { data: res } = await base.post("core/verify-email/", {
        user_id: currentUser?.id,
        otp: +code,
      });
      if (res?.status === "success") {
        const user = await getUserData();
        dispatch({ type: "LOGIN_USER", payload: user });
        dispatch({ type: "AUTH_CLOSE" });
        dispatch({ type: "SUCCESS", payload: res?.message });
      }
    } catch (error) {
      if (error?.response?.data) {
        dispatch({ type: "ERROR", payload: error?.response?.data?.detail });
      } else {
        dispatch({
          type: "ERROR",
          payload: "Sorry, email verification failed. Please try again",
        });
      }
      return;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <WrapBox ref={ref} id="scrollRef">
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={7} sm={5} md={4} lg={3}>
              <FlexBox>
                <Hidden mdUp>
                  <IconButton onClick={toggleDrawer} sx={{ cursor: "pointer" }}>
                    <Menu color="primary" />
                  </IconButton>
                </Hidden>
                <img
                  src={LOGO}
                  style={{ width: "70%", cursor: "pointer" }}
                  onClick={() => navigate("/")}
                  alt="logo"
                />
              </FlexBox>
            </Grid>
            <Grid
              item
              xs={5}
              sm={7}
              md={8}
              lg={9}
              sx={{
                display: "flex",
                gap: "1rem",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Hidden mdDown>
                <Box
                  sx={{ display: "flex", gap: "2rem", alignItems: "center" }}
                >
                  {/* <NavLink underline='none' href='/' color={'secondary'}>
                                    <Typography>Home</Typography>
                                </NavLink> */}
                  <NavLink
                    onMouseEnter={(event) => handlePopoverOpenBuy(event)}
                    onMouseLeave={handlePopoverCloseBuy}
                    underline="none"
                    onClick={(e) => routeToPage(e, "/homes-for-sale")}
                    color={"secondary"}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      Buy <DownArrow />
                    </Typography>
                    {/* Buy PopOver */}
                    <Popover
                      sx={{ pointerEvents: "none", top: "3%" }}
                      open={open}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      onClose={handlePopoverCloseBuy}
                      //disableRestoreFocus
                      PaperProps={{
                        style: {
                          backgroundColor: "transparent",
                          boxShadow: "none",
                          borderRadius: 0,
                          pointerEvents: "auto !important",
                        },
                      }}
                    >
                      <Box
                        bgcolor={"transparent"}
                        sx={{
                          position: "relative",
                          mt: "10px",
                          "&::before": {
                            backgroundColor: "white",
                            content: '""',
                            display: "block",
                            position: "absolute",
                            width: 12,
                            height: 12,
                            top: -6,
                            transform: "rotate(45deg)",
                            left: "calc(50% - 6px)",
                          },
                        }}
                      />

                      <>
                        <Card
                          elevation={0}
                          sx={{ pointerEvents: "auto" }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <CardContent
                            sx={{ width: "50rem", padding: "2.5rem 3rem" }}
                          >
                            <Grid container spacing={2}>
                              <Grid item sm={3}>
                                <Typography
                                  variant="h6"
                                  mb={1.5}
                                  sx={{ fontSize: "1.1rem" }}
                                >
                                  Home For Sale
                                </Typography>
                                <Stack>
                                  <NavItem
                                    underline="none"
                                    color={"textSecondary"}
                                    onClick={(e) =>
                                      routeToPage(e, "/homes-for-sale")
                                    }
                                  >
                                    <Typography variant="body2">
                                      Home Search
                                    </Typography>
                                  </NavItem>
                                  <NavItem
                                    underline="none"
                                    color={"textSecondary"}
                                    onClick={(e) =>
                                      routeToPage(e, "/open-houses")
                                    }
                                  >
                                    <Typography variant="body2">
                                      Open Houses
                                    </Typography>
                                  </NavItem>

                                  <NavItem
                                    underline="none"
                                    color={"textSecondary"}
                                    onClick={(e) =>
                                      routeToPage(e, "/sold-homes")
                                    }
                                  >
                                    <Typography variant="body2">
                                      Sold Homes
                                    </Typography>
                                  </NavItem>
                                </Stack>
                              </Grid>
                              <Grid item sm={3}>
                                <Typography
                                  variant="h6"
                                  mb={1.5}
                                  sx={{ fontSize: "1.1rem" }}
                                >
                                  BPO Buying Options
                                </Typography>
                                <Stack>
                                  <NavItem
                                    underline="none"
                                    color={"textSecondary"}
                                    onClick={(e) => routeToPage(e, "/partners")}
                                  >
                                    <Typography variant="body2">
                                      {" "}
                                      Buy With Partner Agent
                                    </Typography>
                                  </NavItem>
                                  <NavItem
                                    underline="none"
                                    color={"textSecondary"}
                                    onClick={(e) => routeToPage(e, "/agents")}
                                  >
                                    <Typography variant="body2">
                                      Find A Buyer Agent
                                    </Typography>
                                  </NavItem>
                                </Stack>
                              </Grid>
                              <Grid item sm={3}>
                                <Typography
                                  variant="h6"
                                  mb={1.5}
                                  sx={{ fontSize: "1.1rem" }}
                                >
                                  Resources
                                </Typography>
                                <Stack>
                                  <NavItem
                                    underline="none"
                                    color={"textSecondary"}
                                    onClick={(e) =>
                                      routeToPage(e, "/blog/buyer-checklist")
                                    }
                                  >
                                    <Typography variant="body2">
                                      Buyer's Checklist
                                    </Typography>
                                  </NavItem>
                                  <NavItem
                                    underline="none"
                                    color={"textSecondary"}
                                    onClick={(e) =>
                                      routeToPage(e, "/blog/buyer-firsttimer")
                                    }
                                  >
                                    <Typography variant="body2">
                                      First Time Buyers
                                    </Typography>
                                  </NavItem>
                                  {/* <NavItem underline='none' color={'textSecondary'} href='#'>
                                                                    <Typography variant='body2'>Housing Market</Typography>
                                                                </NavItem> */}
                                </Stack>
                              </Grid>
                              <Grid item sm={3}>
                                <Typography
                                  variant="h6"
                                  mb={1.5}
                                  sx={{ fontSize: "1.1rem" }}
                                >
                                  Join BPO Homes
                                </Typography>
                                <Stack>
                                  <NavItem
                                    underline="none"
                                    color={"textSecondary"}
                                    onClick={(e) => routeToPage(e, "/join")}
                                  >
                                    <Typography variant="body2">
                                      Interested in Joining the team?
                                    </Typography>
                                  </NavItem>
                                </Stack>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </>
                    </Popover>
                  </NavLink>
                  <NavLink
                    underline="none"
                    onClick={(e) => routeToPage(e, "/homes-for-rent")}
                    sx={{ cursor: "pointer" }}
                    color={"secondary"}
                  >
                    <Typography sx={{ fontWeight: 300 }}>Rent</Typography>
                  </NavLink>
                  <NavLink
                    onMouseEnter={(event) => handlePopoverOpenSell(event)}
                    onMouseLeave={handlePopoverCloseSell}
                    underline="none"
                    onClick={(e) => routeToPage(e, "/sell")}
                    color={"secondary"}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      Sell <DownArrow />
                    </Typography>
                    {/* Sell PopOver */}
                    <Popover
                      id="mouse-over-popover"
                      sx={{ pointerEvents: "none", top: "3%" }}
                      open={open2}
                      anchorEl={anchorEl2}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      onClose={handlePopoverCloseSell}
                      PaperProps={{
                        style: {
                          backgroundColor: "transparent",
                          boxShadow: "none",
                          borderRadius: 0,
                          pointerEvents: "auto !important",
                        },
                      }}
                    >
                      <Box
                        bgcolor={"transparent"}
                        sx={{
                          position: "relative",
                          mt: "10px",
                          "&::before": {
                            backgroundColor: "white",
                            content: '""',
                            display: "block",
                            position: "absolute",
                            width: 12,
                            height: 12,
                            top: -6,
                            transform: "rotate(45deg)",
                            left: "calc(50% - 6px)",
                          },
                        }}
                      />
                      <Card
                        elevation={0}
                        sx={{ pointerEvents: "auto" }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <CardContent
                          sx={{ width: "50rem", padding: "2.5rem 3rem" }}
                        >
                          <Grid container spacing={2}>
                            <Grid item sm={3}>
                              <Typography
                                variant="h6"
                                mb={1.5}
                                sx={{ fontSize: "1.1rem" }}
                              >
                                My Home
                              </Typography>
                              <Stack>
                                <NavItem
                                  underline="none"
                                  color={"textSecondary"}
                                  onClick={(e) =>
                                    routeToPage(e, "/blog/sellers-guide")
                                  }
                                >
                                  <Typography variant="body2">
                                    A BPO Home Value
                                  </Typography>
                                </NavItem>
                                <NavItem
                                  underline="none"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (currentUser) {
                                      accessDashboard("/profile");
                                    } else {
                                      dispatch({ type: "AUTH_LOGIN" });
                                    }
                                  }}
                                  color={"textSecondary"}
                                >
                                  <Typography variant="body2">
                                    My BPO Dashboard
                                  </Typography>
                                </NavItem>
                              </Stack>
                            </Grid>
                            <Grid item sm={3}>
                              <Typography
                                variant="h6"
                                mb={1.5}
                                sx={{ fontSize: "1.1rem" }}
                              >
                                BPO Selling Options
                              </Typography>
                              <Stack>
                                <NavItem
                                  underline="none"
                                  color={"textSecondary"}
                                  onClick={(e) => routeToPage(e, "/partners")}
                                >
                                  <Typography variant="body2">
                                    Sell with a BPO Homes Agent
                                  </Typography>
                                </NavItem>
                                <NavItem
                                  underline="none"
                                  color={"textSecondary"}
                                  onClick={(e) => routeToPage(e, "/agents")}
                                >
                                  <Typography variant="body2">
                                    Find A Listing Agent
                                  </Typography>
                                </NavItem>
                              </Stack>
                            </Grid>
                            <Grid item sm={3}>
                              <Typography
                                variant="h6"
                                mb={1.5}
                                sx={{ fontSize: "1.1rem" }}
                              >
                                Resources
                              </Typography>
                              <Stack>
                                <NavItem
                                  underline="none"
                                  color={"textSecondary"}
                                  onClick={(e) =>
                                    routeToPage(e, "/blog/sellers-guide")
                                  }
                                >
                                  <Typography variant="body2">
                                    Seller's Guide
                                  </Typography>
                                </NavItem>
                                <NavItem
                                  underline="none"
                                  color={"textSecondary"}
                                  onClick={(e) =>
                                    routeToPage(e, "/blog/home-improvement")
                                  }
                                >
                                  <Typography variant="body2">
                                    Home Improvement Trends
                                  </Typography>
                                </NavItem>
                                <NavItem
                                  underline="none"
                                  color={"textSecondary"}
                                  onClick={(e) =>
                                    routeToPage(e, "/blog/explore")
                                  }
                                >
                                  <Typography variant="body2">
                                    Explore My Options
                                  </Typography>
                                </NavItem>
                              </Stack>
                            </Grid>
                            <Grid item sm={3}>
                              <Typography
                                variant="h6"
                                mb={1.5}
                                sx={{ fontSize: "1.1rem" }}
                              >
                                Join BPO Homes
                              </Typography>
                              <Stack>
                                <NavItem
                                  underline="none"
                                  color={"textSecondary"}
                                  onClick={(e) => routeToPage(e, "/join")}
                                >
                                  <Typography variant="body2">
                                    Interested in Joining the team?
                                  </Typography>
                                </NavItem>
                                <NavItem
                                  underline="none"
                                  color={"textSecondary"}
                                  onClick={(e) =>
                                    routeToPage(e, "/realty-agents")
                                  }
                                >
                                  <Typography variant="body2">
                                    BPO Agent Offices
                                  </Typography>
                                </NavItem>
                              </Stack>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Popover>
                  </NavLink>
                  <NavLink
                    onMouseEnter={(event) => handlePopoverOpenAgent(event)}
                    onMouseLeave={handlePopoverCloseAgent}
                    underline="none"
                    onClick={(e) => routeToPage(e, "/agents")}
                    color={"secondary"}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      Agent Finder
                      <DownArrow />
                    </Typography>
                    {/* Agents PopOver */}
                    <Popover
                      id="mouse-over-popover"
                      sx={{ pointerEvents: "none", top: "3%" }}
                      open={open3}
                      anchorEl={anchorEl3}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      onClose={handlePopoverCloseAgent}
                      PaperProps={{
                        style: {
                          backgroundColor: "transparent",
                          boxShadow: "none",
                          borderRadius: 0,
                          pointerEvents: "auto !important",
                        },
                      }}
                    >
                      <Box
                        bgcolor={"transparent"}
                        sx={{
                          position: "relative",
                          mt: "10px",
                          "&::before": {
                            backgroundColor: "white",
                            content: '""',
                            display: "block",
                            position: "absolute",
                            width: 12,
                            height: 12,
                            top: -6,
                            transform: "rotate(45deg)",
                            left: "calc(50% - 6px)",
                          },
                        }}
                      />
                      <Card
                        elevation={0}
                        sx={{ pointerEvents: "auto" }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <CardContent sx={{ width: "10rem", padding: "2rem" }}>
                          <Grid container spacing={2}>
                            <Grid item sm={12}>
                              <Typography
                                variant="h6"
                                mb={1.5}
                                sx={{ fontSize: "1.1rem" }}
                              >
                                BPO Agents
                              </Typography>
                              <Stack>
                                <NavItem
                                  underline="none"
                                  color={"textSecondary"}
                                  onClick={(e) => routeToPage(e, "/agents")}
                                >
                                  <Typography variant="body2">
                                    Find an Agent
                                  </Typography>
                                </NavItem>
                                <NavItem
                                  underline="none"
                                  color={"textSecondary"}
                                  onClick={(e) => routeToPage(e, "/agentComp")}
                                >
                                  <Typography variant="body2">
                                    Join as an Agent
                                  </Typography>
                                </NavItem>
                                <NavItem
                                  underline="none"
                                  color={"textSecondary"}
                                  onClick={(e) =>
                                    routeToPage(e, "/realty-agents")
                                  }
                                >
                                  <Typography variant="body2">
                                    BPO Realty Agents
                                  </Typography>
                                </NavItem>
                              </Stack>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Popover>
                  </NavLink>

                  <NavLink
                    underline="none"
                    target="_blank"
                    href={`https://${process.env.REACT_APP_URL.includes('dev') ? 'testing.' : ''}industryprofessionals.com`}
                    sx={{ cursor: "pointer" }}
                    color={"secondary"}
                  >
                    <Typography>Industry Professionals</Typography>
                  </NavLink>
                  <NavLink
                    underline="none"
                    href={`${process.env.REACT_APP_BPOHOMESTECH_REDIRECT}order-bpo-report`}
                    color={"secondary"}
                  >
                    <Typography>Order BPO Report</Typography>
                  </NavLink>
                  {/* ------------------------------Brokerage Popover-------------------------- */}
                  <NavLink
                    onMouseEnter={(event) => handlePopoverOpenBrokerage(event)}
                    onMouseLeave={handlePopoverCloseBrokerage}
                    underline="none"
                    onClick={(e) => routeToPage(e, "/brokerage")}
                    color={"secondary"}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      Join Our Brokerage
                      <DownArrow />
                    </Typography>
                    {/* Brokerage PopOver */}
                    <Popover
                      id="mouse-over-popover"
                      sx={{ pointerEvents: "none", top: "3%" }}
                      open={open4}
                      anchorEl={anchorEl4}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      onClose={handlePopoverCloseAgent}
                      PaperProps={{
                        style: {
                          backgroundColor: "transparent",
                          boxShadow: "none",
                          borderRadius: 0,
                          pointerEvents: "auto !important",
                        },
                      }}
                    >
                      <Box
                        bgcolor={"transparent"}
                        sx={{
                          position: "relative",
                          mt: "10px",
                          "&::before": {
                            backgroundColor: "white",
                            content: '""',
                            display: "block",
                            position: "absolute",
                            width: 12,
                            height: 12,
                            top: -6,
                            transform: "rotate(45deg)",
                            left: "calc(50% - 6px)",
                          },
                        }}
                      />
                      <Card
                        elevation={0}
                        sx={{ pointerEvents: "auto" }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <CardContent sx={{ width: "10rem", padding: "2rem" }}>
                          <Grid container spacing={2}>
                            <Grid item sm={12}>
                              <Typography
                                variant="h6"
                                mb={1.5}
                                sx={{ fontSize: "1.1rem" }}
                              >
                                Brokerage
                              </Typography>
                              <Stack>
                                <NavItem
                                  underline="none"
                                  color={"textSecondary"}
                                  onClick={(e) => routeToPage(e, "/agentComp")}
                                >
                                  <Typography variant="body2">
                                    Join as an Agent
                                  </Typography>
                                </NavItem>
                                <NavItem
                                  underline="none"
                                  color={"textSecondary"}
                                  onClick={(e) => routeToPage(e, "/brokerage")}
                                >
                                  <Typography variant="body2">
                                    Join our Internship Program
                                  </Typography>
                                </NavItem>
                                <NavItem
                                  underline="none"
                                  color={"textSecondary"}
                                  onClick={(e) => routeToPage(e, "/partners")}
                                  sx={{ cursor: "pointer" }}
                                >
                                  <Typography variant="body2">
                                    Partner Agent Program
                                  </Typography>
                                </NavItem>
                              </Stack>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Popover>
                  </NavLink>
                  {/* ------------xx------------------Brokerage Popover---------xx----------------- */}

                  {/* <NavLink
                    underline="none"
                    onClick={(e) => routeToPage(e, "/brokerage")}
                    sx={{ cursor: "pointer" }}
                    color={"secondary"}
                  >
                    <Typography>Join Our Brokerage</Typography>
                  </NavLink>
                  <NavLink
                    underline="none"
                    onClick={(e) => routeToPage(e, "/agentComp")}
                    sx={{ cursor: "pointer" }}
                    color={"secondary"}
                  >
                    <Typography>Agent</Typography>
                  </NavLink> */}
                </Box>
              </Hidden>

              {currentUser && currentUser.role === "realty_agent" ? (
                <Box
                  display={"flex"}
                  gap=".4rem"
                  ml={"1rem"}
                  alignItems="center"
                >
                  {/* <Hidden xlDown>
                                    <IconButton>
                                        <HelpOutline fontSize='small' />
                                    </IconButton>
                                </Hidden> */}
                  <Avatar
                    onClick={(e) => handleClick(e)}
                    sx={{
                      ml: 1,
                      height: "2.8rem",
                      width: "2.8rem",
                      bgcolor: "primary.main",
                      cursor: "pointer",
                    }}
                  >
                    {currentUser.first_name?.charAt(0).toUpperCase()}
                  </Avatar>

                  <Popover
                    sx={{ mt: 1 }}
                    open={openProfile}
                    anchorEl={anchorElProfile}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                  >
                    <Box width="19rem">
                      <Box padding={"1.5rem"} textAlign="center">
                        <Box
                          position={"relative"}
                          width="fit-content"
                          m={"0 auto"}
                        >
                          <Avatar
                            src={
                              currentUser?.profile_image
                                ? currentUser?.profile_image
                                : null
                            }
                            sx={{
                              ml: 1,
                              height: "4rem",
                              width: "4rem",
                              m: "0 auto",
                              bgcolor: "secondary.main",
                            }}
                          >
                            {currentUser?.profile_image ? null : (
                              <Typography variant="h4">
                                {currentUser?.first_name
                                  ?.charAt(0)
                                  .toUpperCase()}
                              </Typography>
                            )}
                          </Avatar>
                          {currentUser && currentUser?.is_email_verified && (
                            <Tooltip title="Verified Account" arrow>
                              <Verified
                                color="primary"
                                sx={{
                                  fontSize: "1.8rem",
                                  position: "absolute",
                                  bottom: "-7px",
                                  right: "-7px",
                                }}
                              />
                            </Tooltip>
                          )}
                        </Box>
                        <Typography variant="h6" mt={2}>
                          {currentUser?.first_name +
                            " " +
                            currentUser?.last_name}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          mb={2}
                        >
                          {currentUser?.email}
                        </Typography>
                        <Button
                          sx={{
                            border: `1px solid ${grey[300]}`,
                            borderRadius: "5px",
                            textTransform: "none",
                            width: "75%",
                          }}
                          color="secondary"
                          onClick={() => accessDashboard("/agent-dashboard")}
                        >
                          Manage your account
                        </Button>
                      </Box>
                      <Divider sx={{ bgcolor: grey[100] }} />
                      <Box padding={"1rem"}>
                        <MenuItem
                          component="a"
                          sx={{ borderRadius: "8px", fontSize: "1.1rem" }}
                          onClick={() => accessDashboard("/agent-dashboard")}
                        >
                          Dashboard
                        </MenuItem>
                        <MenuItem
                          component="a"
                          sx={{ borderRadius: "8px", fontSize: "1.1rem" }}
                          onClick={() => accessDashboard("/profile")}
                        >
                          My Profile
                        </MenuItem>
                        <MenuItem
                          component="a"
                          sx={{ borderRadius: "8px", fontSize: "1.1rem" }}
                          onClick={() => accessDashboard("/leads-notification")}
                        >
                          Leads Notification
                        </MenuItem>
                        <MenuItem
                          component="a"
                          sx={{ borderRadius: "8px", fontSize: "1.1rem" }}
                          onClick={() => accessDashboard("/my-subscriptions")}
                        >
                          My Subscriptions
                        </MenuItem>
                      </Box>
                      <Divider sx={{ bgcolor: grey[100] }} />
                      <Box padding={"1.5rem 2rem"} textAlign={"center"}>
                        <Button
                          variant="outlined"
                          color="secondary"
                          disableElevation
                          sx={{
                            textTransform: "none",
                            background: "transparent",

                            "&:hover": {
                              color: "#fff",
                              background: (theme) =>
                                theme.palette.secondary.main,
                            },
                          }}
                          onClick={signOut}
                        >
                          Sign Out
                        </Button>
                        <span
                          style={{
                            display: "flex",
                            marginTop: "1rem",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                          }}
                        >
                          <Typography>
                            <Link
                              sx={{
                                fontSize: ".9rem",
                                color: grey[500],
                                cursor: "pointer",
                                "&:hover": {
                                  color: (theme) => theme.primaryColor,
                                },
                              }}
                              underline="none"
                              href="#"
                            >
                              Terms of Services
                            </Link>
                          </Typography>
                          <FiberManualRecord sx={{ fontSize: ".4rem" }} />
                          <Typography>
                            <Link
                              sx={{
                                fontSize: ".9rem",
                                color: grey[500],
                                cursor: "pointer",
                                "&:hover": {
                                  color: (theme) => theme.primaryColor,
                                },
                              }}
                              underline="none"
                              href="/privacy"
                            >
                              Privacy Policy
                            </Link>
                          </Typography>
                        </span>
                      </Box>
                    </Box>
                  </Popover>
                </Box>
              ) : currentUser && currentUser.role === "client" ? (
                <Box
                  display={"flex"}
                  gap=".4rem"
                  ml={"1rem"}
                  alignItems="center"
                >
                  {/* <Hidden lgDown>
                                    <IconButton>
                                        <HelpOutline fontSize='small' />
                                    </IconButton>
                                </Hidden> */}
                  <Avatar
                    onClick={(e) => handleClick(e)}
                    sx={{
                      ml: 1,
                      height: "2.8rem",
                      width: "2.8rem",
                      bgcolor: "primary.main",
                      cursor: "pointer",
                    }}
                  >
                    {currentUser.first_name?.charAt(0).toUpperCase()}
                  </Avatar>

                  <Popover
                    sx={{ mt: 1 }}
                    open={openProfile}
                    anchorEl={anchorElProfile}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                  >
                    <Box width="19rem">
                      <Box padding={"1.5rem"} textAlign="center">
                        <Box
                          position={"relative"}
                          width="fit-content"
                          m={"0 auto"}
                        >
                          <Avatar
                            src={
                              currentUser?.profile_image
                                ? currentUser?.profile_image
                                : null
                            }
                            sx={{
                              ml: 1,
                              height: "4rem",
                              width: "4rem",
                              m: "0 auto",
                              bgcolor: "secondary.main",
                            }}
                          >
                            {currentUser?.profile_image ? null : (
                              <Typography variant="h4">
                                {currentUser?.first_name
                                  ?.charAt(0)
                                  .toUpperCase()}
                              </Typography>
                            )}
                          </Avatar>
                          {currentUser && currentUser?.is_email_verified && (
                            <Tooltip title="Verified Account" arrow>
                              <Verified
                                color="primary"
                                sx={{
                                  fontSize: "1.8rem",
                                  position: "absolute",
                                  bottom: "-7px",
                                  right: "-7px",
                                }}
                              />
                            </Tooltip>
                          )}
                        </Box>
                        <Typography variant="h6" mt={2}>
                          {currentUser?.first_name +
                            " " +
                            currentUser?.last_name}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          mb={2}
                        >
                          {currentUser?.email}
                        </Typography>
                        <Button
                          sx={{
                            border: `1px solid ${grey[300]}`,
                            borderRadius: "5px",
                            textTransform: "none",
                            width: "75%",
                          }}
                          color="secondary"
                          onClick={() => accessDashboard("/profile")}
                        >
                          Manage your account
                        </Button>
                      </Box>
                      <Divider sx={{ bgcolor: grey[100] }} />
                      <Box padding={"1rem"}>
                        <MenuItem
                          component="a"
                          sx={{ borderRadius: "8px", fontSize: "1.1rem" }}
                          onClick={() => accessDashboard("/client-dashboard")}
                        >
                          Dashboard
                        </MenuItem>
                        <MenuItem
                          component="a"
                          sx={{ borderRadius: "8px", fontSize: "1.1rem" }}
                          onClick={() => accessDashboard("/profile")}
                        >
                          My Profile
                        </MenuItem>
                        <MenuItem
                          component="a"
                          sx={{ borderRadius: "8px", fontSize: "1.1rem" }}
                          onClick={() => accessDashboard("/profile#homes")}
                        >
                          Saved Homes
                        </MenuItem>
                        <MenuItem
                          component="a"
                          sx={{ borderRadius: "8px", fontSize: "1.1rem" }}
                        >
                          My Team
                        </MenuItem>
                      </Box>
                      <Divider sx={{ bgcolor: grey[100] }} />
                      <Box padding={"1.5rem 2rem"} textAlign={"center"}>
                        <Button
                          variant="outlined"
                          color="secondary"
                          disableElevation
                          sx={{
                            textTransform: "none",
                            background: "transparent",

                            "&:hover": {
                              color: "#fff",
                              background: (theme) =>
                                theme.palette.secondary.main,
                            },
                          }}
                          onClick={signOut}
                        >
                          Sign Out
                        </Button>
                        <span
                          style={{
                            display: "flex",
                            marginTop: "1rem",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                          }}
                        >
                          <Typography>
                            <Link
                              sx={{
                                fontSize: ".9rem",
                                color: grey[500],
                                cursor: "pointer",
                                "&:hover": {
                                  color: (theme) => theme.primaryColor,
                                },
                              }}
                              underline="none"
                              href="#"
                            >
                              Terms of Services
                            </Link>
                          </Typography>
                          <FiberManualRecord sx={{ fontSize: ".4rem" }} />
                          <Typography>
                            <Link
                              sx={{
                                fontSize: ".9rem",
                                color: grey[500],
                                cursor: "pointer",
                                "&:hover": {
                                  color: (theme) => theme.primaryColor,
                                },
                              }}
                              underline="none"
                              href="#"
                            >
                              Privacy Policy
                            </Link>
                          </Typography>
                        </span>
                      </Box>
                    </Box>
                  </Popover>
                </Box>
              ) : (
                <Button
                  sx={{
                    textTransform: "none",
                    borderRadius: "50px",
                    width: "8rem",
                    marginLeft: "1rem",
                    height: "2.5rem",
                  }}
                  variant="contained"
                  onClick={() => dispatch({ type: "AUTH_LOGIN" })}
                  size="small"
                  disableElevation
                  color="secondary"
                >
                  Register/Login
                </Button>
              )}
            </Grid>
          </Grid>
        </Container>
      </WrapBox>

      {/* Auth Modal */}
      <Dialog
        open={auth ? true : false}
        fullWidth
        maxWidth={"xs"}
        onClose={() => {
          loginDispatch({ type: "RESET" });
          signupDispatch({ type: "RESET" });
          dispatch({ type: "AUTH_CLOSE" });
        }}
      >
        <DialogContent
          sx={{
            "-ms-overflow-style": "none" /* IE and Edge */,
            " scrollbar-width": "none" /* Firefox */,
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {!currentUser && (
            <Box padding={"1.5rem .5rem"}>
              <img src={LOGO} className={classes.logo} alt="logo" />
              <Box padding="1rem 1rem 0 1rem" display="flex" gap={"2rem"}>
                <Typography
                  variant="body1"
                  sx={{ paddingBottom: "5px" }}
                  className={`${isLogin && classes.hoverActive}`}
                >
                  <Link
                    underline="none"
                    sx={{ cursor: "pointer" }}
                    onClick={() => setIsLogin(true)}
                    color={"textPrimary"}
                    className={`${isLogin && "list-active"}`}
                  >
                    Sign in
                  </Link>
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ paddingBottom: "5px" }}
                  className={`${!isLogin && classes.hoverActive}`}
                >
                  <Link
                    underline="none"
                    sx={{ cursor: "pointer" }}
                    onClick={() => setIsLogin(false)}
                    color={"textPrimary"}
                    className={`${!isLogin && "list-active"}`}
                  >
                    New account
                  </Link>
                </Typography>
              </Box>
              <Divider sx={{ background: grey[200] }} />
              {isLogin && (
                <>
                  <Box padding="2rem 1rem 1rem 1rem">
                    <FormLabel className={classes.label}>Email</FormLabel>
                    <StyledTextField
                      variant="outlined"
                      name={"email"}
                      onChange={(e) =>
                        loginDispatch({
                          type: "EMAIL",
                          payload: e.target.value,
                        })
                      }
                      className={classes.field}
                      value={loginDetails.email}
                      fullWidth
                      placeholder="Enter email"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email fontSize="small" sx={{ color: grey[300] }} />
                          </InputAdornment>
                        ),
                      }}
                    />

                    <FormLabel className={classes.label}>Password</FormLabel>
                    <StyledTextField
                      variant="outlined"
                      name="password"
                      type={"password"}
                      inputProps={{ minLength: 8 }}
                      onChange={(e) =>
                        loginDispatch({
                          type: "PASSWORD",
                          payload: e.target.value,
                        })
                      }
                      className={classes.field}
                      value={loginDetails.password}
                      fullWidth
                      placeholder="Enter password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Lock fontSize="small" sx={{ color: grey[300] }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <LoadingButton
                      loading={loading}
                      variant="contained"
                      disableElevation
                      color="secondary"
                      fullWidth
                      sx={{
                        textTransform: "none",
                        height: "3.5rem",
                        marginBottom: "1rem",
                      }}
                      onClick={() => onSubmit("login")}
                    >
                      Sign in
                    </LoadingButton>
                    <Typography variant="body2" textAlign={"center"}>
                      <Link
                        underline="none"
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          dispatch({ type: "AUTH_CLOSE" });
                          setForgetPass(true);
                        }}
                        color={"secondary"}
                      >
                        Forgot your password?
                      </Link>
                    </Typography>
                  </Box>
                  <Divider sx={{ margin: "1.4rem 0" }} orientation="horizontal">
                    <Typography color="textSecondary" variant="body2">
                      or continue with
                    </Typography>{" "}
                  </Divider>
                  <ButtonGroup fullWidth>
                    <Button
                      className={classes.btnGoogle}
                      sx={{ textTransform: "none" }}
                      onClick={googleAuth}
                      disableElevation
                      variant="outlined"
                      fullWidth
                      color="secondary"
                    >
                      <img
                        style={{ marginRight: "1rem" }}
                        width="12%"
                        src={Google}
                        alt="google_icon"
                      />{" "}
                      Google
                    </Button>
                    <Button
                      className={classes.btnGoogle}
                      sx={{ textTransform: "none" }}
                      onClick={facebookAuth}
                      startIcon={<Facebook fontSize="large" />}
                      disableElevation
                      variant="outlined"
                      fullWidth
                      color="secondary"
                    >
                      Facebook
                    </Button>
                    {/* <AppleLogin
                                    clientId="com.bpohomes.bpohomes"
                                    redirectURI="http://localhost:3001/"
                                    usePopup={true}
                                    callback={appleResponse} // Catch the response
                                    scope="email name"
                                    responseMode="query"
                                    render={ renderProps => (
                                            <Button
                                                className={classes.btnGoogle} sx={{textTransform: 'none'}}
                                                startIcon={<Apple sx={{ color: '#000' }} />}
                                                disableElevation
                                                variant='outlined'
                                                fullWidth
                                                color='secondary'
                                                onClick={renderProps.onClick}
                                            >
                                                Apple
                                            </Button>
                                    )}
                                >

                                </AppleLogin> */}
                  </ButtonGroup>
                </>
              )}
              {!isLogin && (
                <Box
                  padding="2rem 1rem 1rem 1rem"
                  height={"34.2rem"}
                  overflow="scroll"
                >
                  <FormLabel className={classes.label}>First Name</FormLabel>
                  <StyledTextField
                    variant="outlined"
                    className={classes.field}
                    value={signupDetails.firstname}
                    fullWidth
                    placeholder="Enter Firstname"
                    name="first_name"
                    onChange={(e) =>
                      signupDispatch({
                        type: "FIRSTNAME",
                        payload: e.target.value,
                      })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Person fontSize="small" sx={{ color: grey[300] }} />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <FormLabel className={classes.label}>Last Name</FormLabel>
                  <StyledTextField
                    variant="outlined"
                    className={classes.field}
                    value={signupDetails.lastname}
                    fullWidth
                    placeholder="Enter Lastname"
                    name="last_name"
                    onChange={(e) =>
                      signupDispatch({
                        type: "LASTNAME",
                        payload: e.target.value,
                      })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Person fontSize="small" sx={{ color: grey[300] }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormLabel className={classes.label}>Email</FormLabel>
                  <StyledTextField
                    variant="outlined"
                    type={"email"}
                    name={"email"}
                    className={classes.field}
                    value={signupDetails.email}
                    fullWidth
                    placeholder="Enter email"
                    onChange={(e) =>
                      signupDispatch({ type: "EMAIL", payload: e.target.value })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email fontSize="small" sx={{ color: grey[300] }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormLabel className={classes.label}>Phone</FormLabel>
                  <StyledTextField
                    variant="outlined"
                    name="phone"
                    className={classes.field}
                    value={signupDetails.phone}
                    onChange={(e) =>
                      signupDispatch({ type: "PHONE", payload: e.target.value })
                    }
                    fullWidth
                    placeholder="Phone number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Call fontSize="small" sx={{ color: grey[300] }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormLabel className={classes.label}>Zip/Postal</FormLabel>
                  <StyledTextField
                    variant="outlined"
                    name="zip"
                    className={classes.field}
                    value={signupDetails.zip}
                    onChange={(e) =>
                      signupDispatch({ type: "ZIP", payload: e.target.value })
                    }
                    fullWidth
                    placeholder="Zip/Postal Code"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Contacts
                            fontSize="small"
                            sx={{ color: grey[300] }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <FormLabel className={classes.label}>Password</FormLabel>
                  <StyledTextField
                    variant="outlined"
                    name="password"
                    type={"password"}
                    className={classes.field}
                    value={signupDetails.password}
                    onChange={(e) =>
                      signupDispatch({
                        type: "PASSWORD",
                        payload: e.target.value,
                      })
                    }
                    inputProps={{ minLength: 8 }}
                    fullWidth
                    placeholder="Password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Lock fontSize="small" sx={{ color: grey[300] }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormLabel className={classes.label}>
                    Confirm Password
                  </FormLabel>
                  <StyledTextField
                    variant="outlined"
                    type={"password"}
                    className={classes.field}
                    value={signupDetails.confirmPassword}
                    onChange={(e) =>
                      signupDispatch({
                        type: "CONFIRM_PASSWORD",
                        payload: e.target.value,
                      })
                    }
                    name="passwordConfirmation"
                    inputProps={{ minLength: 8 }}
                    fullWidth
                    placeholder="Confirm Password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Lock fontSize="small" sx={{ color: grey[300] }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormControlLabel
                    sx={{ marginTop: "-8px", mb: 2 }}
                    control={
                      <Checkbox
                        checked={signupDetails.isAgent}
                        id="prof"
                        name={"isProfessional"}
                        onChange={() =>
                          signupDispatch({
                            type: "AGENT",
                            payload: !signupDetails.isAgent,
                          })
                        }
                      />
                    }
                    label={
                      <Typography variant="body2">
                        I am a BPO Homes Realty Agent
                      </Typography>
                    }
                  />
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    disableElevation
                    color="secondary"
                    fullWidth
                    sx={{
                      textTransform: "none",
                      height: "3.5rem",
                      marginBottom: "1rem",
                    }}
                    onClick={() => onSubmit("signup")}
                  >
                    Sign Up
                  </LoadingButton>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "13px" }}
                    color={"textSecondary"}
                  >
                    By signing in, you agree to BPO Homes's{" "}
                    <Link>Terms of Use</Link> and <Link>Privacy Policy</Link>
                  </Typography>
                </Box>
              )}
            </Box>
          )}

          {/* Email Verification */}
          {currentUser && !currentUser?.is_email_verified && (
            <Box padding={"2rem 1.5rem"} textAlign={"center"}>
              <img src={EMAIL} width={"22%"} alt="email" />
              <Typography variant="h5" fontWeight={500} mt={1} mb={1}>
                Verify Email
              </Typography>
              <Typography variant="body1" mb={2}>
                To start using bpohomes.com, we have sent a verification token
                to your email (<b>{currentUser?.email}</b>)
              </Typography>
              <Stack
                direction={"row"}
                gap={".6rem"}
                my={3}
                width={"100%"}
                mx={"auto"}
              >
                {new Array(6).fill(0).map((el, i) => {
                  return (
                    <input
                      key={i}
                      autoFocus={i === 0 ? true : false}
                      ref={
                        i === 0
                          ? ref1
                          : i === 1
                            ? ref2
                            : i === 2
                              ? ref3
                              : i === 3
                                ? ref4
                                : i === 4
                                  ? ref5
                                  : ref6
                      }
                      type="text"
                      maxLength={1}
                      onChange={(e) => autoFocusHandler(e)}
                      style={{
                        textAlign: "center",
                        height: "4rem",
                        fontSize: "2rem",
                        width: "100%",
                        borderRadius: "3px",
                        border: "1px solid lightgrey",
                      }}
                    />
                  );
                })}
              </Stack>
              <LoadingButton
                loading={loading}
                variant="contained"
                disableElevation
                color="secondary"
                fullWidth
                size="large"
                sx={{ textTransform: "none", marginBottom: "1rem" }}
                onClick={verifyAccount}
              >
                Verify Account
              </LoadingButton>
              <Typography
                variant="body2"
                onClick={resendCode}
                color={"secondary"}
                fontWeight={400}
                sx={{ cursor: "pointer", textDecoration: "underline" }}
              >
                Didn't receive email. Resend
              </Typography>
            </Box>
          )}
        </DialogContent>
      </Dialog>

      {/* Forget Password */}
      <Dialog open={forgetPass} maxWidth={"xs"}>
        <DialogContent sx={{ position: "relative" }}>
          <IconButton
            sx={{ position: "absolute", top: "3%", right: "2%" }}
            onClick={() => {
              setForgetPass(false);
              dispatch({ type: "AUTH_LOGIN" });
            }}
          >
            <Close fontSize="small" />
          </IconButton>
          <Box padding={"1.5rem .5rem"}>
            {page === 1 && (
              <Box>
                <Typography textAlign={"center"} variant="h6">
                  Forgot your password?
                </Typography>
                <Typography mb={4} textAlign={"center"} variant="body2">
                  Enter your email address and we'll send you a link to set your
                  password.
                </Typography>

                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  placeholder="Enter Email"
                  onChange={(e) => setResetEmail(e.target.value)}
                />
                <LoadingButton
                  loading={loading}
                  disableElevation
                  onClick={onPassReset}
                  sx={{
                    textTransform: "none",
                    marginTop: "1rem",
                    fontWeight: 500,
                  }}
                  fullWidth
                  variant="contained"
                  color="secondary"
                >
                  Send Reset Link
                </LoadingButton>
                <Typography
                  mt={1}
                  textAlign={"center"}
                  sx={{ fontSize: ".9rem" }}
                >
                  Know your password?
                  <Button
                    disableElevation
                    color="secondary"
                    onClick={() => {
                      setForgetPass(false);
                      dispatch({ type: "AUTH_LOGIN" });
                    }}
                    sx={{ textTransform: "none" }}
                    variant="text"
                  >
                    Sign in
                  </Button>
                </Typography>
              </Box>
            )}
            {page === 2 && (
              <Box>
                <Typography textAlign={"center"} variant="h6">
                  Your forgot password email was sent!
                </Typography>
                <Typography mb={4} textAlign={"center"} variant="body2">
                  If the email address has an account, an email will be sent
                  with a link to reset your password.
                </Typography>
                <Typography>Email sent to: {resetEmail}</Typography>
                <Divider sx={{ my: "1rem" }} />
                <Typography
                  mt={1}
                  textAlign={"center"}
                  sx={{ fontSize: ".9rem" }}
                >
                  Know your password?
                  <Button
                    disableElevation
                    color="secondary"
                    onClick={() => {
                      setForgetPass(false);
                      dispatch({ type: "AUTH_LOGIN" });
                    }}
                    sx={{ textTransform: "none" }}
                    variant="text"
                  >
                    Sign in
                  </Button>
                </Typography>
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>

      <SideBar open={openDraw} toggle={toggleDrawer} />
    </>
  );
};

const mapStateToprops = (state) => {
  //console.log(state)
  return state;
};

export default connect(mapStateToprops, {
  userSignUp,
  userLogin,
  signoutUser,
  googleOAuth,
  facebookOAuth,
  googleOneTap,
})(NavBar);
