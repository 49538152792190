import { Box, Button, Card, CardContent, Container, Divider, TextField, MenuItem, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, Slide, Dialog, DialogContent, IconButton, RadioGroup, Radio, FormControlLabel } from '@mui/material'
import { makeStyles } from '@mui/styles'
import LOGO from '../../assets/logo-wide.png'
import BG from '../../assets/joinbpo.png'
import HomeIcon from '../../assets/home-vec.svg'
import Award from '../../assets/awardz.svg'
import Analyzer from '../../assets/analyzer.svg'
import FileList from '../../assets/fileList.svg'
import Partake from '../../assets/partake.png'
import { Check, KeyboardArrowDown, Close, CheckCircle } from '@mui/icons-material'
import { grey } from '@mui/material/colors'
import React, { useEffect, useState } from 'react'
import { option1, option2, option3 } from '../../constants'
// import { switchToSignup, toggleAuthModal } from '../../actions'
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux'
import base from '../../api/apis'
import { LoadingButton } from '@mui/lab'
import { Link } from 'react-router-dom'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles = makeStyles(theme => ({
    banner: {
        height: '70vh',
        backgroundImage: `url(${BG})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            height: '40vh'
        }
    },
    listItem: {
        gap: '1rem',
        alignItems: 'flex-start',
        '& img': {
            width: '70%',
            marginTop: '.5rem'
        },
        '& svg': {
            marginTop: '1rem'
        }
    },
    divider: {
        height: '40vh',
        backgroundImage: `linear-gradient(180deg, rgba(0,0,0,50%), rgba(0,0,0,50%)), url(${Partake})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        [theme.breakpoints.down('sm')]: {
            height: '50vh',
            textAlign: 'center'
        }
    },
    card: {
        boxShadow: '0 2px 5px rgba(0,0,0, 10%)',
    }
}))


const CardItem = ({ title, amount, transactionFee, transactionFeeSubText, subscribed, subscriptionStatus, realtySubscribe, classes, subscription, showDetails }) => {
    return (
        <Card variant='outlined' className={classes.card} sx={{ borderRadius: '10px' }}>
            <CardContent style={{ padding: '2.5rem', textAlign: 'center' }}>
                <Typography sx={{ fontWeight: 600 }} variant='h6'>{title}</Typography>
                <Typography variant='body2' sx={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }} onClick={showDetails}>View Package Details</Typography>
                <KeyboardArrowDown fontSize='small' sx={{ margin: '1rem 0' }} />
                <Typography style={{ fontWeight: 600 }} variant='h5'>${((amount || 0) / 100).toFixed(2)}</Typography>
                <Typography variant='body2' color={'textSecondary'}>per month</Typography>

                <Typography sx={{ fontWeight: 400, margin: '1rem 0' }} variant='body1'>&</Typography>
                <Typography style={{ fontWeight: 500, lineHeight: 'normal' }} variant='h6'>{transactionFee}</Typography>
                <Typography mb={1} style={{ fontWeight: 500, lineHeight: 'normal', fontSize: '1.2rem' }} variant='h6'>{transactionFeeSubText}</Typography>
                <Typography variant='body2' fontWeight={400} color={'textSecondary'}>per transaction</Typography>
                {
                    subscribed === subscription && subscriptionStatus === 'active' ?
                        <Button variant='contained' disabled={true} disableElevation color='primary' onClick={() => realtySubscribe(subscription)} fullWidth sx={{ textTransform: 'none', height: '3rem', marginTop: '1rem' }} >Current Subscription</Button>
                        :
                        <Button variant='contained' disableElevation color='primary' onClick={() => realtySubscribe(subscription)} fullWidth sx={{ textTransform: 'none', height: '3rem', marginTop: '1rem' }} >Get Started</Button>
                }

            </CardContent>
        </Card>
    )
}

const JoinBPO = () => {
    const classes = useStyles()
    const currentUser = useSelector(state => state.currentUser)
    const dispatch = useDispatch()
    const [sign, setSign] = useState(false)
    const [option, setOption] = useState()
    const [page, setPage] = useState(0)
    const [office, setOffice] = useState('')
    const [desk, setDesk] = useState('flex')
    const [packageType, setPackageType] = useState()
    const [addons, setAddOns] = useState()
    const [setupFee, setSetupFee] = useState()
    const subsData = localStorage.getItem('usub') ? JSON.parse(localStorage.getItem('usub')) : null
    const subscribed = subsData?.type
    const subscriptionStatus = subsData?.status
    const [show, setShow] = useState(false)


    const getPrices = async () => {
        try {
            const res = await base.get('/register/list_prices/?requiring_type=monthly')
            const packages = res.data[0]?.filter(el => {
                return (el.metadata?.name === 'realty_agent_basic' && el?.metadata?.recurring_type === 'monthly') 
                || (el.metadata?.name === 'realty_agent_pro' && el?.metadata?.recurring_type === 'monthly')
                || (el.metadata?.name === 'realty_agent_elite' && el?.metadata?.recurring_type === 'monthly')
                || (el.metadata?.name === 'realty_agent_ultra' && el?.metadata?.recurring_type === 'monthly')
            })
            console.log(packages)
            const addons = res.data[0]?.filter(el => {
                return el.metadata?.name === 'office_cubicle' || el.metadata?.name === 'team_station' || el?.metadata?.name === 'realty-setup-elite' || el?.metadata?.name === 'realty-setup-pro'
            })
            console.log(addons)

            setSetupFee(addons?.filter(el => el?.metadata?.name === 'realty-setup-elite' || el?.metadata?.name === 'realty-setup-pro' || el?.metadata?.name === 'realty-setup-ultra'))
            setPackageType(packages)
            setAddOns(addons)
        } catch (error) {
            dispatch({ type: 'ERROR', payload: 'Sorry, could not fetch price lists' })
        }
    }

    useEffect(() => {
        getPrices()
        // eslint-disable-next-line
    }, [])

    const deskOptions = {
        flex: { name: 'Flex Desk', price: 0 },
        office_cubicle: { name: 'Office Cubicle', price: 99 },
        team_station: { name: 'Team Station', price: 399 }
    }

    const handleChange = (event) => {
        setDesk(event.target.value);
    };

    const realtySubscribe = async (val) => {
        if (!currentUser) {
            dispatch({ type: 'AUTH_LOGIN' })
        } else {
            if (val === 'realty_agent_basic') {
                setSign(true)
                setOption(val)
                await getPrices()
                // console.log(packageType)
            }
            if (val === 'realty_agent_pro') {
                setSign(true)
                setOption(val)
                await getPrices()
                // console.log(packageType)
            }
            if (val === 'realty_agent_elite') {
                setSign(true)
                setOption(val)
                await getPrices()
                // console.log(packageType)
            }
            if (val === 'realty_agent_ultra') {
                setSign(true)
                setOption(val)
                await getPrices()
                // console.log(packageType)
            }
        }

    };

    const nextStep = () => {
        if (page === 0) {
            if (office === '') {
                return
            }
            setPage(1)
        }
        if (page === 1) {
            if (!desk) {
                return
            }
            setPage(2)
        }
    }
    const stepBack = () => {
        if (page === 1) {
            setPage(0)
        } if (page === 2) {
            setPage(1)
        }
    }

    const closeDialog = () => {
        setPage(0)
        setOffice('')
        setDesk('flex')
        setSign(false)
    }

    const sub1 = packageType?.find(el => el?.metadata?.name === "realty_agent_basic")
    const sub2 = packageType?.find(el => el?.metadata?.name === "realty_agent_pro")
    const sub3 = packageType?.find(el => el?.metadata?.name === "realty_agent_elite")
    const sub4 = packageType?.find(el => el?.metadata?.name === "realty_agent_ultra")

    return (
        <>
            <Box className={classes.banner}>
                <Typography textAlign={'center'} variant='h4' sx={{ color: '#fff', fontWeight: 700 }}>Real Estate at BPO Homes</Typography>
            </Box>
            <Container maxWidth={'xl'}>
                <Box padding={'4rem 0'}>
                    <Typography textAlign={'center'} mb={2} variant='h5' sx={{ fontWeight: 600 }}>Why Join Us?</Typography>
                    <Typography textAlign={'center'} paragraph>The real estate industry offers many opportunities for those who are looking to start or grow their careers. There are many different aspects of the industry, from sales and marketing to property management and development, so there is something for everyone. Join us today for an amazing experience.
                    </Typography>
                    <Divider id='subscribe' sx={{ margin: '2rem 0 0 0', background: grey[300] }} />
                </Box>

                {/* Subscription */}
                <Box pb={4}>
                    <Typography textAlign={'center'} sx={{ fontWeight: 600 }} variant='h6'>100% Commission</Typography>
                    <Typography variant='body2' textAlign={'center'}>(E&O, Legal & Training Fees not included)</Typography>

                    {/* SUbscription Cards */}
                    <Box width={'100%'} margin={'3rem auto'} >
                        <Grid container spacing={4} >
                            <Grid item xs={12} sm={12} lg={3}>
                                <CardItem classes={classes}
                                    title={'Realty Agent Basic'}
                                    amount={sub1?.unit_amount}
                                    transactionFee={'80/20 Split'}
                                    transactionFeeSubText={'7k Commission Cap'}
                                    realtySubscribe={realtySubscribe}
                                    subscription={'realty_agent_basic'}
                                    subscribed={subscribed}
                                    subscriptionStatus={subscriptionStatus}
                                    showDetails={() => { setOption('realty_agent_basic'); setShow(true) }}
                                />
                                {/* <Card variant='outlined' className={classes.card} sx={{ borderRadius: '10px' }}>
                                    <CardContent style={{ padding: '2.5rem', textAlign: 'center' }}>
                                        <Typography sx={{ fontWeight: 600 }} variant='h6'>Realty Agent Basic</Typography>
                                        <Typography variant='body2'>Package Details</Typography>
                                        <KeyboardArrowDown fontSize='small' sx={{ margin: '1rem 0' }} />
                                        <Typography style={{ fontWeight: 600 }} variant='h5'>${sub1?.unit_amount / 100}.00</Typography>
                                        <Typography variant='body2' color={'textSecondary'}>per month</Typography>

                                        <Typography sx={{ fontWeight: 400, margin: '1rem 0' }} variant='body1'>&</Typography>
                                        <Typography style={{ fontWeight: 600 }} variant='h5'>$399.00</Typography>
                                        <Typography variant='body2' color={'textSecondary'}>per transaction</Typography>
                                        {
                                            subscribed === 'subscription1' && subscriptionStatus === 'active' ?
                                                <Button variant='contained' disabled={true} disableElevation color='primary' onClick={() => realtySubscribe('subscription1')} fullWidth sx={{ textTransform: 'none', height: '3rem', marginTop: '1rem' }} >Current Subscription</Button>
                                                :
                                                <Button variant='contained' disableElevation color='primary' onClick={() => realtySubscribe('subscription1')} fullWidth sx={{ textTransform: 'none', height: '3rem', marginTop: '1rem' }} >Get Started</Button>
                                        }

                                    </CardContent>
                                </Card> */}
                            </Grid>

                            <Grid item xs={12} sm={12} lg={3}>
                                <CardItem classes={classes}
                                    title={'Realty Agent Pro'}
                                    amount={sub2?.unit_amount}
                                    transactionFee={'80/20 Split'}
                                    transactionFeeSubText={'5k Commission Cap'}
                                    realtySubscribe={realtySubscribe}
                                    subscription={'realty_agent_pro'}
                                    subscribed={subscribed}
                                    subscriptionStatus={subscriptionStatus}
                                    showDetails={() => { setOption('realty_agent_pro'); setShow(true) }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} lg={3}>
                                <CardItem classes={classes}
                                    title={'Realty Agent Elite'}
                                    amount={sub3?.unit_amount}
                                    transactionFee={'80/20 Split'}
                                    transactionFeeSubText={'4k Commission Cap'}
                                    realtySubscribe={realtySubscribe}
                                    subscription={'realty_agent_elite'}
                                    subscribed={subscribed}
                                    subscriptionStatus={subscriptionStatus}
                                    showDetails={() => { setOption('realty_agent_elite'); setShow(true) }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} lg={3}>
                                <CardItem classes={classes}
                                    title={'Realty Agent Ultra'}
                                    amount={sub4?.unit_amount}
                                    transactionFee={'80/20 Split'}
                                    transactionFeeSubText={'2k Commission Cap'}
                                    realtySubscribe={realtySubscribe}
                                    subscription={'realty_agent_ultra'}
                                    subscribed={subscribed}
                                    subscriptionStatus={subscriptionStatus}
                                    showDetails={() => { setOption('realty_agent_ultra'); setShow(true) }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>

            <Box padding={'6rem 0'} bgcolor={grey[200]}>
                <Container>
                    <Grid container spacing={5}>
                        <Grid item sm={12} md={6} lg={5}
                            sx={{ display: 'flex', alignItems: { sm: 'center', lg: 'flex-start' }, justifyContent: 'center', flexDirection: 'column', paddingRight: '1rem' }}>
                            <Typography variant='h5' sx={{ fontWeight: 600 }} gutterBottom>Top Real Estate Tech Platforms to Attract Sellers & Buyers</Typography>
                            <Typography paragraph>BPO Homes Realty Provides you Everything you need to be a Top Producer</Typography>
                            <Button size='large' variant='contained' href='#subscribe' disableElevation color='primary' sx={{ textTransform: 'none', color: '#000', width: '10rem' }}>Learn More</Button>
                        </Grid>
                        <Grid item sm={12} md={6} lg={7}>
                            <List>
                                <ListItem className={classes.listItem}>
                                    <ListItemIcon><img src={HomeIcon} alt={'home'} /></ListItemIcon>
                                    <ListItemText>
                                        <Typography variant='h6'>BPO Premier Agent</Typography>
                                        <Typography>With the BPO Homes Partner Premier Agent Program we provide leads to Sellers & Buyers directly from BPOHomes.com with only a 10%-15% referral fee payable to BPO Homes Realty.
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <ListItemIcon><img src={Award} alt={'home'} /></ListItemIcon>
                                    <ListItemText>
                                        <Typography variant='h6'>Deal Analyzer </Typography>
                                        <Typography>BPO Homes Deal Analyzer is 100% FREE for our BPO Homes Realty Agents. Deal Analyzer allows Agents to provide analysis on any property just like an investor & send a detailed Proforma Report to their client professionally branded by the Agent
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <ListItemIcon><img src={Analyzer} alt={'home'} /></ListItemIcon>
                                    <ListItemText>
                                        <Typography variant='h6'>Report Agent </Typography>
                                        <Typography>BPO Homes Report Agent is 100% FREE for our BPO Homes Realty Agents
                                            We build you a custom personal BPO Report Ordering Page.
                                            Don't give away anymore Free CMA’s receive compensation for your market expertise
                                            At BPO Homes we streamlined the BPO Report Process
                                            Agents can earn compensation $$$ for creating BPO Report within Minutes.
                                            Agents also Receive the leads to Buyers & Sellers who request BPO Reports!!
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <ListItemIcon><img src={FileList} alt={'home'} style={{ width: '60%' }} /></ListItemIcon>
                                    <ListItemText>
                                        <Typography variant='h6'>Farm Agent </Typography>
                                        <Typography>Take full control of your Marketing in your Farm Area Neighborhoods. Our BPO Homes Farm Agents receive huge discounts and savings on BPO Homes Farm Areas. We also add in all of your Online Marketing along with all of our other BPO Homes Platforms 100% Free!! </Typography>
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            {/* Divider */}
            <Box className={classes.divider}>
                <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%' }}>
                    <Typography variant='h5' gutterBottom textAlign={'center'}>Partake in the future of real estate.</Typography>
                    <Typography paragraph textAlign={'center'}>Becoming a real estate agent is the best career choice for anyone who enjoys helping people and working in an exciting, rewarding and fast-paced field. Become a real estate agent today and enjoy the benefits of this exciting career.</Typography>
                    <Button size='large' variant='contained' disableElevation color='secondary' href='#subscribe' sx={{ textTransform: 'none', color: '#fff', width: '10rem' }}>Join</Button>
                </Container>
            </Box>

            <Container>
                <Box padding={'4rem 0'}>
                    <Typography textAlign={'center'} variant='h5' sx={{ fontWeight: 600, marginBottom: '2rem' }}>What happens to your career</Typography>
                    <Grid container spacing={4}>
                        <Grid item sm={12} lg={6}>
                            <List>
                                <ListItem className={classes.listItem}>
                                    <ListItemIcon><Check /></ListItemIcon>
                                    <ListItemText>
                                        <Typography variant='h6' sx={{ fontWeight: 600 }}>Gain wealth</Typography>
                                        <Typography paragraph>Opportunity to earn more every year</Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <ListItemIcon><Check /></ListItemIcon>
                                    <ListItemText>
                                        <Typography variant='h6' sx={{ fontWeight: 600 }}>Gain skills</Typography>
                                        <Typography paragraph>The best team, training, technology</Typography>
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item sm={12} md={6} lg={6}>
                            <ListItem className={classes.listItem}>
                                <ListItemIcon><Check /></ListItemIcon>
                                <ListItemText>
                                    <Typography variant='h6' sx={{ fontWeight: 600 }}>Gain responsibilities</Typography>
                                    <Typography paragraph>51% of our real estate managers and directors started in agent or support roles</Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                                <ListItemIcon><Check /></ListItemIcon>
                                <ListItemText>
                                    <Typography variant='h6' sx={{ fontWeight: 600 }}>Another point</Typography>
                                    <Typography paragraph>A brief explanation</Typography>
                                </ListItemText>
                            </ListItem>
                        </Grid>
                    </Grid>
                </Box>
            </Container>


            {/* Subscribe */}
            <Dialog open={sign} fullScreen fullWidth TransitionComponent={Transition}>
                <Box bgcolor={'secondary.main'} sx={{ display: 'flex', justifyContent: 'space-between', padding: '1.5rem 4rem', marginBottom: '3rem', color: '#fff' }}>
                    <Typography color='paper' variant='h6' >Join BPO Realty</Typography>
                    <IconButton onClick={closeDialog}><Close sx={{ color: '#fff' }} fontSize='small' /></IconButton>
                </Box>
                <DialogContent>
                    <Elements stripe={stripePromise}>
                        <Container>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                <img src={LOGO} width='20%' alt='logo' />
                                <span>
                                    <Typography textAlign={'right'} variant='h6' sx={{ fontSize: '1.1rem' }}>Call Sales: 1 888 616 5270</Typography>
                                    <Typography textAlign={'right'} variant='body2' color='GrayText'>9:00 AM to 6:30 PM PST, Monday to Friday</Typography>
                                </span>
                            </Box>
                            <Box sx={{ padding: '3rem 0', margin: '1rem auto 3rem auto' }}>
                                {
                                    page === 0 &&
                                    <span>
                                        <Typography mb={2} textAlign={'center'} variant='h6' sx={{ fontWeight: 600 }}>Choose Office Location</Typography>
                                        <TextField fullWidth variant='outlined' defaultValue={''} value={office} select onChange={(e) => setOffice(e.target.value)} >
                                            <MenuItem value={'BPO Homes  Headquarters - 6920 Santa Teresa Boulevard, San Jose, CA, USA'}>
                                                <Typography>BPO Homes  Headquarters - 6920 Santa Teresa Boulevard, San Jose, CA, USA</Typography>
                                            </MenuItem>
                                        </TextField>
                                        <Button variant='contained' sx={{ width: '10rem', margin: '2rem auto', display: 'block' }} onClick={nextStep} disableElevation color='secondary'>Continue</Button>
                                    </span>
                                }
                                {
                                    page === 1 &&
                                    <>
                                        {
                                            option === 'realty_agent_basic' &&
                                            <Grid container spacing={3}>
                                                <Grid item sm={7} >
                                                    <Card elevation={0} variant='outlined' sx={{ borderRadius: '15px' }}>
                                                        <CardContent sx={{ padding: '2rem' }}>
                                                            <Typography textAlign={'center'}>Your Selection Package</Typography>
                                                            <Divider sx={{ margin: '2rem 0' }} />

                                                            <Typography textAlign={'center'} mb={1} variant='h6' sx={{ fontWeight: 600 }}>Realty Agent Basic</Typography>
                                                            <Typography textAlign={'center'} sx={{ fontWeight: 600 }}>Package Details</Typography>
                                                            <List>
                                                                {
                                                                    option1.map((el, index) => {
                                                                        return (
                                                                            <ListItem key={index}>
                                                                                <ListItemIcon sx={{ minWidth: '32px' }}><CheckCircle color='primary' fontSize='small' /></ListItemIcon>
                                                                                <ListItemText>{el}</ListItemText>
                                                                            </ListItem>
                                                                        )
                                                                    })
                                                                }
                                                            </List>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>

                                                <Grid item sm={5} sx={{ height: 'auto' }}>
                                                    <Card elevation={0} variant='outlined' sx={{ borderRadius: '15px', height: '100%' }}>
                                                        <CardContent sx={{ padding: '2rem', height: '100%' }}>
                                                            <Typography textAlign={'left'} variant='h6'>Realty Agent Basic</Typography>
                                                            <Typography mb={'3rem'}>BPO Homes Realty</Typography>
                                                            <Divider sx={{ my: '1rem' }} />
                                                            <RadioGroup
                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                defaultValue={''}
                                                                value={desk}
                                                                name="radio-buttons-group"
                                                                onChange={handleChange}
                                                            >
                                                                <FormControlLabel value="flex" control={<Radio color='secondary' />} label="Flex Desk is FREE " />
                                                                <FormControlLabel value="office_cubicle" control={<Radio color='secondary' />} label="Office Cubicle at $99 " />
                                                                <FormControlLabel value="team_station" control={<Radio color='secondary' />} label="Team Station at $399" />
                                                            </RadioGroup>
                                                            <Divider sx={{ my: '1rem' }} />

                                                            <Typography textAlign={'left'} variant='h6'>Order Summary</Typography>
                                                            <Divider sx={{ margin: '1rem 0' }} />

                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem 0' }}>
                                                                <Typography>Price /month</Typography>
                                                                <Typography variant='h6'>$ {sub1?.unit_amount / 100} <span style={{ fontSize: '.9rem' }}>/month</span></Typography>
                                                            </Box>
                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem 0' }}>
                                                                <Typography>Setup Fee</Typography>
                                                                <Typography variant='h6'>$ {setupFee[1]?.unit_amount / 100}</Typography>
                                                            </Box>
                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem 0' }}>
                                                                <Typography>{deskOptions[desk].name}</Typography>
                                                                {desk === 'flex' && <Typography variant='h6'>$ 0.00</Typography>}
                                                                {desk === 'office_cubicle' && <Typography variant='h6'>$ 99.00</Typography>}
                                                                {desk === 'team_station' && <Typography variant='h6'>$ 399.00</Typography>}
                                                            </Box>
                                                            <Divider sx={{ margin: '1rem 0' }} />
                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem 0' }}>
                                                                <Typography>TOTAL</Typography>
                                                                <Typography variant='h6' sx={{ fontWeight: 600 }}>$ {(sub1?.unit_amount / 100) + deskOptions[desk].price + (setupFee[1]?.unit_amount / 100)}.00</Typography>
                                                            </Box>

                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            </Grid>

                                        }

                                        {
                                            option === 'realty_agent_pro' &&
                                            <Grid container spacing={3}>
                                                <Grid item sm={7} >
                                                    <Card elevation={0} variant='outlined' sx={{ borderRadius: '15px' }}>
                                                        <CardContent sx={{ padding: '2rem' }}>
                                                            <Typography textAlign={'center'}>Your Selection Package</Typography>
                                                            <Divider sx={{ margin: '2rem 0' }} />

                                                            <Typography textAlign={'center'} mb={1} variant='h6' sx={{ fontWeight: 600 }}>Realty Agent Pro</Typography>
                                                            <Typography textAlign={'center'} sx={{ fontWeight: 600 }}>Package Details</Typography>
                                                            <List>
                                                                {
                                                                    option2.map((el, index) => {
                                                                        return (
                                                                            <ListItem key={index}>
                                                                                <ListItemIcon sx={{ minWidth: '32px' }}><CheckCircle color='primary' fontSize='small' /></ListItemIcon>
                                                                                <ListItemText>{el}</ListItemText>
                                                                            </ListItem>
                                                                        )
                                                                    })
                                                                }
                                                            </List>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>

                                                <Grid item sm={5} sx={{ height: 'auto' }}>
                                                    <Card elevation={0} variant='outlined' sx={{ borderRadius: '15px', height: '100%' }}>
                                                        <CardContent sx={{ padding: '2rem', height: '100%' }}>
                                                            <Typography textAlign={'left'} variant='h6'>Realty Agent Pro</Typography>
                                                            <Typography mb={1}>BPO Homes Realty</Typography>
                                                            <Divider sx={{ my: '1rem' }} />
                                                            <RadioGroup
                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                value={desk}
                                                                defaultValue=''
                                                                name="radio-buttons-group"
                                                                onChange={handleChange}
                                                            >
                                                                <FormControlLabel value="flex" control={<Radio color='secondary' />} label="Flex Desk is FREE " />
                                                                <FormControlLabel value="office_cubicle" control={<Radio color='secondary' />} label="Office Cubicle at $99 " />
                                                                <FormControlLabel value="team_station" control={<Radio color='secondary' />} label="Team Station at $399" />
                                                            </RadioGroup>
                                                            <Divider sx={{ my: '1rem' }} />
                                                            <Typography mt={4} textAlign={'left'} variant='h6'>Order Summary</Typography>


                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem 0' }}>
                                                                <Typography>Price /month</Typography>
                                                                <Typography variant='h6'>$ {sub2?.unit_amount / 100}.00</Typography>
                                                            </Box>
                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem 0' }}>
                                                                <Typography>Setup Fee</Typography>
                                                                <Typography variant='h6'>$ {setupFee[1]?.unit_amount / 100}</Typography>
                                                            </Box>
                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem 0' }}>
                                                                <Typography>{deskOptions[desk].name}</Typography>
                                                                {desk === 'flex' && <Typography variant='h6'>$ 0.00</Typography>}
                                                                {desk === 'office_cubicle' && <Typography variant='h6'>$ 99.00</Typography>}
                                                                {desk === 'team_station' && <Typography variant='h6'>$ 399.00</Typography>}
                                                            </Box>
                                                            <Divider sx={{ margin: '1rem 0' }} />
                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem 0' }}>
                                                                <Typography>TOTAL</Typography>
                                                                <Typography variant='h6' sx={{ fontWeight: 600 }}>$ {(sub2?.unit_amount / 100) + deskOptions[desk].price + (setupFee[1]?.unit_amount / 100)}.00</Typography>
                                                            </Box>

                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            </Grid>

                                        }

                                        {
                                            option === 'realty_agent_elite' &&
                                            <Grid container spacing={3}>
                                                <Grid item sm={7} >
                                                    <Card elevation={0} variant='outlined' sx={{ borderRadius: '15px' }}>
                                                        <CardContent sx={{ padding: '2rem' }}>
                                                            <Typography textAlign={'center'}>Your Selection Package</Typography>
                                                            <Divider sx={{ margin: '2rem 0' }} />

                                                            <Typography textAlign={'center'} mb={1} variant='h6' sx={{ fontWeight: 600 }}>Realty Agent Elite</Typography>
                                                            <Typography textAlign={'center'} sx={{ fontWeight: 600 }}>Package Details</Typography>
                                                            <List>
                                                                {
                                                                    option3?.map((el, index) => {
                                                                        return (
                                                                            <ListItem key={index}>
                                                                                <ListItemIcon sx={{ minWidth: '32px' }}><CheckCircle color='primary' fontSize='small' /></ListItemIcon>
                                                                                <ListItemText>{el}</ListItemText>
                                                                            </ListItem>
                                                                        )
                                                                    })
                                                                }
                                                            </List>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>

                                                {/* Combo/Elite Setup */}
                                                <Grid item sm={5} sx={{ height: 'auto' }}>
                                                    <Card elevation={0} variant='outlined' sx={{ borderRadius: '15px', height: '100%' }}>
                                                        <CardContent sx={{ padding: '2rem', height: '100%' }}>
                                                            <Typography textAlign={'left'} variant='h6'>Realty Agent Elite</Typography>
                                                            <Typography mb={1}>BPO Homes Realty</Typography>
                                                            <Divider sx={{ my: '1rem' }} />
                                                            <RadioGroup
                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                value={desk}
                                                                defaultValue=''
                                                                name="radio-buttons-group"
                                                                onChange={handleChange}
                                                            >
                                                                <FormControlLabel value="flex" control={<Radio color='secondary' />} label="Flex Desk is FREE " />
                                                                <FormControlLabel value="office_cubicle" control={<Radio color='secondary' />} label="Office Cubicle at $99 " />
                                                                <FormControlLabel value="team_station" control={<Radio color='secondary' />} label="Team Station at $399" />
                                                            </RadioGroup>
                                                            <Divider sx={{ my: '1rem' }} />
                                                            <Typography mt={4} textAlign={'left'} variant='h6'>Order Summary</Typography>


                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem 0' }}>
                                                                <Typography>Price /month</Typography>
                                                                <Typography variant='h6'>$ {sub3?.unit_amount / 100}</Typography>
                                                            </Box>
                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem 0' }}>
                                                                <Typography>Setup Fee</Typography>
                                                                <Typography variant='h6'>$ {setupFee[0]?.unit_amount / 100}</Typography>
                                                            </Box>
                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem 0' }}>
                                                                <Typography>{deskOptions[desk].name}</Typography>
                                                                {desk === 'flex' && <Typography variant='h6'>$ 0.00</Typography>}
                                                                {desk === 'office_cubicle' && <Typography variant='h6'>$ 99.00</Typography>}
                                                                {desk === 'team_station' && <Typography variant='h6'>$ 399.00</Typography>}
                                                            </Box>
                                                            <Divider sx={{ margin: '1rem 0' }} />
                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem 0' }}>
                                                                <Typography>TOTAL</Typography>
                                                                <Typography variant='h6' sx={{ fontWeight: 600 }}>$ {(sub3?.unit_amount / 100) + deskOptions[desk].price + (setupFee[0]?.unit_amount / 100)}.00</Typography>
                                                            </Box>

                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            </Grid>

                                        }
                                        {
                                            option === 'realty_agent_ultra' &&
                                            <Grid container spacing={3}>
                                                <Grid item sm={7} >
                                                    <Card elevation={0} variant='outlined' sx={{ borderRadius: '15px' }}>
                                                        <CardContent sx={{ padding: '2rem' }}>
                                                            <Typography textAlign={'center'}>Your Selection Package</Typography>
                                                            <Divider sx={{ margin: '2rem 0' }} />

                                                            <Typography textAlign={'center'} mb={1} variant='h6' sx={{ fontWeight: 600 }}>Realty Agent Ultra</Typography>
                                                            <Typography textAlign={'center'} sx={{ fontWeight: 600 }}>Package Details</Typography>
                                                            <List>
                                                                {
                                                                    option3?.map((el, index) => {
                                                                        return (
                                                                            <ListItem key={index}>
                                                                                <ListItemIcon sx={{ minWidth: '32px' }}><CheckCircle color='primary' fontSize='small' /></ListItemIcon>
                                                                                <ListItemText>{el}</ListItemText>
                                                                            </ListItem>
                                                                        )
                                                                    })
                                                                }
                                                            </List>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>

                                                {/* Combo/Elite/Ultra Setup */}
                                                <Grid item sm={5} sx={{ height: 'auto' }}>
                                                    <Card elevation={0} variant='outlined' sx={{ borderRadius: '15px', height: '100%' }}>
                                                        <CardContent sx={{ padding: '2rem', height: '100%' }}>
                                                            <Typography textAlign={'left'} variant='h6'>Realty Agent Ultra</Typography>
                                                            <Typography mb={1}>BPO Homes Realty</Typography>
                                                            <Divider sx={{ my: '1rem' }} />
                                                            <RadioGroup
                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                value={desk}
                                                                defaultValue=''
                                                                name="radio-buttons-group"
                                                                onChange={handleChange}
                                                            >
                                                                <FormControlLabel value="flex" control={<Radio color='secondary' />} label="Flex Desk is FREE " />
                                                                <FormControlLabel value="office_cubicle" control={<Radio color='secondary' />} label="Office Cubicle at $99 " />
                                                                <FormControlLabel value="team_station" control={<Radio color='secondary' />} label="Team Station at $399" />
                                                            </RadioGroup>
                                                            <Divider sx={{ my: '1rem' }} />
                                                            <Typography mt={4} textAlign={'left'} variant='h6'>Order Summary</Typography>


                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem 0' }}>
                                                                <Typography>Price /month</Typography>
                                                                <Typography variant='h6'>$ {sub4?.unit_amount / 100}</Typography>
                                                            </Box>
                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem 0' }}>
                                                                <Typography>Setup Fee</Typography>
                                                                <Typography variant='h6'>$ {setupFee[0]?.unit_amount / 100}</Typography>
                                                            </Box>
                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem 0' }}>
                                                                <Typography>{deskOptions[desk].name}</Typography>
                                                                {desk === 'flex' && <Typography variant='h6'>$ 0.00</Typography>}
                                                                {desk === 'office_cubicle' && <Typography variant='h6'>$ 99.00</Typography>}
                                                                {desk === 'team_station' && <Typography variant='h6'>$ 399.00</Typography>}
                                                            </Box>
                                                            <Divider sx={{ margin: '1rem 0' }} />
                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem 0' }}>
                                                                <Typography>TOTAL</Typography>
                                                                <Typography variant='h6' sx={{ fontWeight: 600 }}>$ {(sub4?.unit_amount / 100) + deskOptions[desk].price + (setupFee[0]?.unit_amount / 100)}.00</Typography>
                                                            </Box>

                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            </Grid>

                                        }

                                        <Box mt={'3rem'} sx={{ textAlign: 'center' }}>
                                            <Typography variant='body2'>By clicking Checkout, you agree with our <Link to={'/terms-of-use'}>Terms & Condition</Link> and <Link to={'/privacy-policy'}>Privacy Policy</Link></Typography>
                                            <Button variant='outlined' size='large' onClick={stepBack} color='secondary' disableElevation sx={{ width: '15rem', margin: '1rem', textTransform: 'none' }}>Go back</Button>
                                            <StripePay
                                                termsAgreed={true}
                                                addon={desk === 'flex' ? '' : addons?.filter(el => el.metadata?.name === desk)[0]}
                                                packageType={packageType?.filter(el => el.metadata?.name === option)[0]}
                                                setup={option === 'realty_agent_basic' || option === 'realty_agent_pro' ? setupFee[1] : setupFee[0]}
                                            />
                                        </Box>
                                    </>
                                }
                                {
                                    page === 2 &&
                                    <Elements stripe={stripePromise}>
                                        <Box>
                                            <Typography>Loading Stripe</Typography>
                                        </Box>
                                    </Elements>
                                }
                            </Box>

                        </Container>
                    </Elements>
                </DialogContent>
            </Dialog>

            {/* Package Details */}
            <Dialog open={show} onClose={() => setShow(false)}>
                <DialogContent>
                    <Typography variant='h6' textAlign={'center'} fontWeight={500}>{option === 'realty_agent_basic' ? 'Realty Agent Basic' : option === 'realty_agent_pro' ? 'Realty Agent Pro' : option === 'realty_agent_elite' ? 'Realty Agent Elite' : 'Realty Agent Ultra'} </Typography>
                    <Typography variant='body1' textAlign={'center'} fontWeight={300}>Package Details</Typography>
                    <Divider sx={{ my: 2 }} />
                    <List>
                        {
                            option === 'realty_agent_basic' &&
                            option1.map((el, index) => {
                                return (
                                    <ListItem key={index}>
                                        <ListItemIcon sx={{ minWidth: '32px' }}><CheckCircle color='primary' fontSize='small' /></ListItemIcon>
                                        <ListItemText><Typography variant='body2'>{el}</Typography></ListItemText>
                                    </ListItem>
                                )
                            })
                        }
                        {
                            option === 'realty_agent_pro' &&
                            option2.map((el, index) => {
                                return (
                                    <ListItem key={index}>
                                        <ListItemIcon sx={{ minWidth: '32px' }}><CheckCircle color='primary' fontSize='small' /></ListItemIcon>
                                        <ListItemText><Typography variant='body2'>{el}</Typography></ListItemText>
                                    </ListItem>
                                )
                            })
                        }
                        {
                            option === 'realty_agent_elite' &&
                            option3.map((el, index) => {
                                return (
                                    <ListItem key={index}>
                                        <ListItemIcon sx={{ minWidth: '32px' }}><CheckCircle color='primary' fontSize='small' /></ListItemIcon>
                                        <ListItemText><Typography variant='body2'>{el}</Typography></ListItemText>
                                    </ListItem>
                                )
                            })
                        }
                        {
                            option === 'realty_agent_ultra' &&
                            option3.map((el, index) => {
                                return (
                                    <ListItem key={index}>
                                        <ListItemIcon sx={{ minWidth: '32px' }}><CheckCircle color='primary' fontSize='small' /></ListItemIcon>
                                        <ListItemText><Typography variant='body2'>{el}</Typography></ListItemText>
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                </DialogContent>
            </Dialog>

        </>
    )
}

export default JoinBPO


const StripePay = ({ termsAgreed, addon, packageType, setup }) => {
    const dispatch = useDispatch()
    const stripe = useStripe();
    const [load, setLoad] = useState(false)

    const confirmPayment = () => {

        if (!termsAgreed) {
            dispatch({ type: 'ERROR', payload: 'Please agree to the terms and conditions' });
            return;
        }
        setLoad(true)
        //body data
        const body = {
            interval: "monthly",
            price_data: (packageType?.metadata?.name === 'realty_agent_basic' || packageType?.metadata?.name === 'realty_agent_pro' || packageType?.metadata?.name === 'realty_agent_elite' || packageType?.metadata?.name === 'realty_agent_ultra') && addon === '' ? [
                {
                    price: packageType.id,
                    quantity: 1
                },
                {
                    price: setup.id,
                    quantity: 1
                }
            ] : [
                {
                    price: packageType.id,
                    quantity: 1
                },
                {
                    price: addon.id,
                    quantity: 1
                },
                {
                    price: setup?.id,
                    quantity: 1
                }
            ],
            cancel_url: window.location.href,
            success_url: window.location.origin + `/payment-success?type=${packageType.nickname}&addon=${addon.nickname}&`,
            payment_method_types: ["card"],
            mode: "subscription",
            package: "bpo-realty-agent"
        };

        base
            .post('/register/create_subscription/', body)
            .then(({ data: { sessionId } }) => {
                if (!sessionId) {
                    dispatch({ type: 'ERROR', payload: 'Something went wrong' });
                    return;
                }
                stripe.redirectToCheckout({ sessionId });
                setLoad(false)
            })
            .catch((err) => {
                dispatch({ type: 'ERROR', payload: 'Something went wrong' });
            });
    };

    return (
        // <Elements stripe={stripePromise}>
        <LoadingButton loading={load} variant='contained' size='large' onClick={confirmPayment} color='secondary' disableElevation sx={{ width: '15rem', margin: '1rem', textTransform: 'none' }}>Checkout</LoadingButton>
        // </Elements>
    );
};